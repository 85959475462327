







import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
    name: 'route-loader'
})
export default class RouteLoader extends Vue {
}
