import {Store} from 'vuex'

export class NotificationEngine {
    timer = setInterval(this.handleTimer.bind(this), 1000);
    store: Store<any>;
    tag: string;

    constructor(store: Store<any>, tag: string) {
        this.store = store;
        this.tag = tag;
    }

    handleTimer() {
        const messages = this.store.state[this.tag];
        messages.forEach((x: any, index: number) => {
            x.timeout -= 1000;
            if (x.timeout <= 0) {
                this.store.dispatch('removeMessage', index);
            }
        });
    }
}

export const createNotifications = (store: Store<any>, tag: string) => new NotificationEngine(store, tag);
