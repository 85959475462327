import VueRouter, {RouteConfig} from 'vue-router';
import {SFRouteConfig, SFRouterOptions} from './core';
import SFFrame from '../dom/SFFrame.vue';
import RouterManager from '@/core/sfframe/router/RouterManager';

const routeParser = r => {
    const {
        remote,
        title,
        ...route
    } = r;

    if (remote) {
        route.component = SFFrame;
        route.props = {
            pageName: route.name,
            remote
        };
    }

    return route as RouteConfig;
}

export default class SFRouter extends VueRouter {
    routerManager: RouterManager = new RouterManager(30);

    constructor(options: SFRouterOptions) {
        super({
            ...options,
            routes: options.routes ? options.routes.map(routeParser) : []
        });
    }

    addRoutes(routes: SFRouteConfig[]) {
        super.addRoutes(routes.map(routeParser));
    }

    setMatcher(matcher: any) {
        // @ts-ignore
        this.matcher = matcher;
    }
}
