






import Vue from 'vue'
import Component from 'vue-class-component'
import {Watch} from 'vue-property-decorator';

@Component({
    name: 'page-default'
})
export default class PageDefault extends Vue {
    text = '';

    setText() {
        const solution = this.$store.state.solutions.find(s => s.Id === this.$store.state.solution);
        this.text = this.$t('Default_Page_Text', {userName: this.$store.state.user.name, solutionName: solution.Name}).toString();
    }

    created() {
        this.setText();
    }

    @Watch('$store.state.lang')
    langHandler() {
        this.setText();
    }
}
