import axios, {AxiosInstance, AxiosRequestConfig} from 'axios'
import {checkUser, getSettings, getToken} from '@/services/application';
import {generateIdentityUrl} from '@/utils/application';
import store from '@/store';
import {rootCamelize} from '@/utils/camelize';
import {v4 as uuidv4} from 'uuid';
import i18n from '@/plugins/i18n'

const responseMiddlewareSuccess = loader => (response: any) => {
    if (loader) {
        store.dispatch('setLoading', false);
    }

    return response;
}

const gotoLogin = async () => {
    window.location.href = await generateIdentityUrl();
}

const responseMiddlewareError = (notification: boolean, loader?: boolean, secure?: boolean) => (error: any) => {
    const notificationCheck = () => {
        const response = error && error.response ? error.response : error.message ? {data: {messages: [error.message]}} : {data: null}
        const data = rootCamelize(response.data);

        if (data && data.messages) {
            data.messages.forEach((m: string) => {
                store.dispatch('addMessage', {
                    text: i18n.t('Server_Error') + m,
                    type: 'error',
                    timeout: 8000
                });
            });
        } else {
            store.dispatch('addMessage', {
                text: i18n.t('Server_Error') + error.response.statusText,
                type: 'error',
                timeout: 8000
            });
        }
    }

    if (error.response?.status === 401) {
        return gotoLogin();
    }

    if (notification) {
        notificationCheck();
    }

    if (loader) {
        store.dispatch('setLoading', false);
    }

    return Promise.reject(error);
}

export const parseBody = (res: any) => {
    return res.data.Data;
}

export const bodyParserJs = res => {
    const data = res.data;

    data.List = data.list.map(x => ({
        Id: x.id,
        HasChilds: x.hasChilds,
        ...x
    }))

    data.TotalCount = data.totalCount

    return data;
}

type customHeaderFunc = () => object;

export interface ApiInstanceOptions {
    customHeaders?: object | customHeaderFunc;

    errorMiddleware?(d: Date): void;

    loader?: boolean;

    disableErrorNotifications?: boolean;
}

export const createSecureInstance = (baseUrl: string, options?: ApiInstanceOptions): AxiosInstance => {
    const instance = axios.create({
        baseURL: baseUrl,
        timeout: 3600000
    });

    instance.interceptors.request.use(function(config): AxiosRequestConfig {
        const user = checkUser();
        const token = getToken();

        if (!user) {
            return config;
        }

        const customHeaders = options?.customHeaders || {}
        const headers = typeof customHeaders === 'function' ? customHeaders() : customHeaders

        config.headers = {
            Authorization: `Bearer ${token}`,
            token: `${token}`,
            'Access-Control-Allow-Origin': '*',
            tenantId: user['tenant-id'],
            'p360-solution-id': getSettings().solution,
            transactionId: uuidv4(),
            'X-Timezone-Offset': (new Date().getTimezoneOffset() / 60) * -1,
            'Accept-Language': i18n.t('Culture'),
            ...headers
        };

        if (options?.loader) {
            store.dispatch('setLoading', true);
        }

        return config;
    }, error => Promise.reject(error));

    instance.interceptors.response.use(responseMiddlewareSuccess(options?.loader), responseMiddlewareError(!options?.disableErrorNotifications, options?.loader, true));

    return instance;
}

export const createUnsecureInstance = (baseUrl: string, options?: ApiInstanceOptions): AxiosInstance => {
    const instance = axios.create({
        baseURL: baseUrl,
        timeout: 3600000
    });

    instance.interceptors.request.use(function(config): AxiosRequestConfig {
        const customHeaders = options?.customHeaders || {}
        const headers = typeof customHeaders === 'function' ? customHeaders() : customHeaders

        config.headers = {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json-patch+json',
            transactionId: uuidv4(),
            'Accept-Language': i18n.t('Culture'),
            ...headers
        };

        if (options?.loader) {
            store.dispatch('setLoading', true);
        }

        return config;
    }, error => Promise.reject(error));

    if (options?.loader) {
        store.dispatch('setLoading', true);
    }

    instance.interceptors.response.use(responseMiddlewareSuccess(options?.loader), responseMiddlewareError(!options?.disableErrorNotifications, options?.loader, false));

    return instance;
}
