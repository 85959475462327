export const stopHandler = event => {
    event.stopPropagation();
    event.preventDefault();

    return false;
}

export const delay = ms => new Promise(resolve => {
    setTimeout(resolve, ms)
})
