import axios from 'axios';
import {uuidv4} from '@/utils/application';
import policyApi from '@/services/api/policyApi';

export const getRouteMenuData = async () => {
    let json: any = null;
    const menuData: any[] = [];
    const routerData: any[] = [];

    if (process.env.VUE_APP_MODE !== 'remote') {
        try {
            json = await axios.get('/menu.json')
        } catch (e) {

        }
    }

    if (json && json.data && Array.isArray(json.data)) {
        json.data.forEach(x => {
            if (x.title) {
                menuData.push({
                    ...x,
                    id: uuidv4()
                });
            }

            if (!x.onlyMenu && x.remote) {
                routerData.push({
                    ...x,
                    id: uuidv4()
                });
            }
        });
    } else {
        const res = await policyApi.getUserRoutePolicy();
        const sortByOrder = (a, b) => a.Order > b.Order ? 1 : -1;

        res.data.Data.filter(x => x.Meta)
            .sort(sortByOrder)
            .forEach(x => {
                try {
                    const item = JSON.parse(x.Meta);

                    if (x.ChildMenuItemPermissions && x.ChildMenuItemPermissions.length > 0) {
                        item.children = [];
                        x.ChildMenuItemPermissions
                            .sort(sortByOrder)
                            .filter(y => y.Meta).forEach(y => {
                                const child = JSON.parse(y.Meta);
                                if (child.title) {
                                    item.children.push({
                                        ...child,
                                        id: y.Id
                                    });
                                }

                                if (!child.onlyMenu && child.remote) {
                                    routerData.push(child);
                                }
                            });
                    } else {
                        if (!item.onlyMenu && item.remote) {
                            routerData.push(item);
                        }
                    }

                    if (item.title) {
                        menuData.push({
                            ...item,
                            id: x.Id
                        });
                    }
                } catch (e) {
                    console.error('Meta parse error', x)
                }
            });
    }

    return {
        menuData,
        routerData
    }
}
