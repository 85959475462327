import {createUnsecureInstance} from '@/services/api/api';
import config from '@/config';
import asyncConfig from '@/services/asyncConfig';
import axios from 'axios';

export default {
    fetchConfigs: async () => {
        const resConfig = await axios.get(`${config.CONFIG_FILE}`);
        const configApiUrl = resConfig.data[config.ConfigKeys.CONFIG_API_URL];

        return axios.get(`${configApiUrl}`);
    },
    getConfig: async (key: string) => {
        return await asyncConfig.get(key);
    }
}
