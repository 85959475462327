import asyncConfig from '@/services/asyncConfig';

const Hotjar = {
    async install(app, options) {
        const hid = await asyncConfig.get('HOTJAR_TC');

        if (hid) {
            const script = document.createTextNode(`(function(h,o,t,j,a,r){
           h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
           h._hjSettings={hjid:${hid},hjsv:6};
           a=o.getElementsByTagName('head')[0];
           r=o.createElement('script');r.async=1;
           r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
           a.appendChild(r);
       })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`);

            const head = document.getElementsByTagName('head')[0];
            const subscript = document.createElement('script');
            subscript.appendChild(script)
            head.appendChild(subscript)
        }
    }
}

export default Hotjar
