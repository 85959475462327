import Vue from 'vue'
import Component from 'vue-class-component'
import config from '@/config';
import {mapActions, mapGetters, mapState} from 'vuex';
import asyncConfig from '@/services/asyncConfig';

@Component({
    components: {},
    computed: {
        languages: () => config.languages,
        ...mapState(['lang', 'menu', 'user', 'solutions', 'loading', 'organization', 'organizations']),
        ...mapGetters(['organizationName']),
        dark: {
            get() {
                return this.$store.state.dark
            },
            set(value) {
                this.$store.dispatch('setDark', value)
            }
        }
    },
    methods: {
        ...mapActions(['setLang', 'signOut', 'toggleMenuVariant'])
    },
    data: () => ({
        menuModel: false,
        solutionsMenu: false,
        message: false,
        hints: true
    })
})
export default class AppBar extends Vue {
    lightLogo = '';
    darkLogo = '';

    handleSelectSolution(solution) {
        if (solution.Uri) {
            // @ts-ignore
            window.open(solution.Uri, '_blank').focus();

            return
        }

        this.$store.dispatch('setSolution', solution.Id);
    }

    async mounted() {
        this.lightLogo = await asyncConfig.get('LOGO_LIGHT') || '/logo_light.png';
        this.darkLogo = await asyncConfig.get('LOGO_DARK') || '/logo_dark.png';

        const title = await asyncConfig.get('TITLE');
        const icon = await asyncConfig.get('ICON');

        if (title) {
            document.title = title ?? 'P360';
        }

        if (icon) {
            let link: any = document.querySelector('link[rel~=\'icon\']');

            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.head.appendChild(link);
            }

            link.href = icon;
        }
    }

    handleSelectOrganizaiton(organization) {
        this.$store.dispatch('setOrganization', organization);
    }

    async openIotDevCenter() {
        await this.$store.dispatch('openIotDevCenter')
    }
}
