import Vue from 'vue'
import {parseToken} from '@/utils/application';
import store from '@/store';
import SFRouter from '@/core/sfframe/router/SFRouter';
import {SFRouteConfig} from '@/core/sfframe/router/core';
import {getRouteMenuData} from '../services/application/policy';
import {getSettings} from '@/services/application';
import policyApi from '@/services/api/policyApi';
import PageNotFound from '@/components/NotFound.vue';
import Default from '@/components/Default.vue';
import {delay} from '@/utils/event';

Vue.use(SFRouter);

const createRouter = () => new SFRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/SetToken/:token',
            name: 'SetToken',
            redirect: 'DefaultHome'

        },
        {
            path: '/404',
            name: '404',
            component: PageNotFound
        }
    ]
})

const router = createRouter();

export function resetRouter() {
    const reset: any = createRouter()
    router.setMatcher(reset.matcher);
    router.options.routes = []
    router.push('/reset');

    if (router.currentRoute.path !== '/') {
        router.push('/');
    }
}

policyApi.getUserSolutions().then(async (res: any) => {
    await store.dispatch('setSolutions', res.data.Data);
});

policyApi.getUserOrganizations().then(async (res: any) => {
    await store.dispatch('setOrganizations', res.data.Data.map(({
        PolicyId,
        PolicyName
    }) => ({
        id: PolicyId,
        name: PolicyName
    })));
});

router.beforeEach(async (to, from, next) => {
    if (to.name === '/SetToken/' || to.path === '/SetToken/') {
        await store.dispatch('setToken', parseToken(to.hash));
        window.location.href = '/';
    }

    if (window.hj) {
        window.hj('stateChange', to.path);
    }

    next();
});

export async function initRouter(reset = false) {
    const solution = getSettings().solution;

    if (solution) {
        if (reset) {
            resetRouter();
        }

        await store.dispatch('updateMenu', []);

        const {
            menuData,
            routerData
        } = await getRouteMenuData();

        await store.dispatch('updateMenu', menuData);

        let hasDefaultPage = false;
        router.addRoutes(routerData.filter(x => !x.onlyMenu).map(route => {
            if (!route.path || route.path === '/') {
                hasDefaultPage = true;
            }
            if (route.redirect || route.remote) {
                return route;
            }

            return {
                ...route,
                component: () => import(`@/views/${route.name}/${route.name}.vue`)
            }
        }) as Array<SFRouteConfig>);

        if (!hasDefaultPage) {
            router.addRoute({
                path: '/',
                name: 'page-default',
                component: Default
            })
        }

        router.addRoute({
            path: '*',
            redirect: '/404'
        })

        await delay(100)
    }
}

export default router;
