














import Vue from 'vue'
import Component from 'vue-class-component'
import AppBar from '@/components/layout/AppBar/AppBar.vue'
import Drawer from '@/components/layout/Drawer.vue'
import {mapState} from 'vuex';
import Notification from '@/components/Notification.vue';

@Component({
    components: {
        Notification,
        AppBar,
        Drawer
    },
    computed: mapState(['lang', 'user'])
})
export default class App extends Vue {
    hideLayout = false;

    created() {
        const params = new URLSearchParams(window.location.search);
        this.hideLayout = params.has('hide-layout');
    }
}
