




import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
    name: 'page-not-found'
})
export default class PageNotFound extends Vue {
    showError = false
    mounted() {
        const params = new URLSearchParams(window.location.search);
        if (!params.has('hide-layout')) {
            window.location.href = '/404?hide-layout'
        } else {
            this.showError = true
        }
    }
}
