import jwtDecode from 'jwt-decode'
import Settings from '@/services/application/Settings';
import {generateIdentityUrl} from '@/utils/application';
import config from '@/config';

export const getToken = () => {
    return localStorage.getItem(config.STORAGE.TOKEN_KEY);
}

export const signOut = async () => {
    localStorage.clear();
    window.location.href = await generateIdentityUrl();

    return null;
}

export const checkUser = (): object | any | undefined => {
    const token = localStorage.getItem(config.STORAGE.TOKEN_KEY);

    try {
        if (token) {
            return jwtDecode(token);
        }
    } catch (e) {
        signOut()
    }

    signOut();
}

export const setToken = (token: string) => {
    localStorage.setItem(config.STORAGE.TOKEN_KEY, token);
}

export const getSettings = (): Settings => {
    const _s = localStorage.getItem(config.STORAGE.SETTINGS_KEY);

    try {
        return _s ? JSON.parse(_s) : {};
    } catch (e) {
        return {};
    }
}

export const setConfig = (value: Settings): Settings => {
    const settings = getSettings();
    const saveSettings = {...settings, ...value};
    localStorage.setItem(config.STORAGE.SETTINGS_KEY, JSON.stringify(saveSettings));

    return getSettings();
}

export interface User {
    name: string;
}

export const getSolution = (): string | undefined => {
    return getSettings().solution;
}

export const getOrganization = (): string | undefined => {
    const {organization} = getSettings();

    if (organization !== null && organization) {
        return organization
    }

    const user = checkUser();

    if (user && user['organization-id']) {
        return user['organization-id'];
    }
}
