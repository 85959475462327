export default class RouterManager {
    #_count = 0;
    readonly #limit;

    constructor(limit = 15) {
        this.#limit = limit;
    }

    count() {
        if (this.#_count > this.#limit) {
            window.location.reload();
        }
        this.#_count++;
    }
}
