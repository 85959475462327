import Vue from 'vue'
import Vuex from 'vuex'
import config from '@/config';
import {getSettings, checkUser, setConfig, signOut, setToken, getOrganization} from '@/services/application';
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import {initRouter} from '@/router/router';
import configApi from '@/services/api/configApi';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        lang: getSettings().lang || config.DEFAULT_LANG,
        dark: getSettings().dark === null ? config.DEFAULT_DARK : getSettings().dark,
        user: checkUser(),
        alert: true,
        messages: new Array<any>(),
        menu: [],
        solution: getSettings().solution,
        organization: getOrganization(),
        solutions: [],
        organizations: [],
        menuVariantMini: getSettings().menuVariantMini,
        loading: false
    },
    getters: {
        organizationName: (state) => {
            const organization: any = state.organizations.find((x: any) => x.id === state.organization);

            return organization?.name || ''
        }
    },
    mutations: {
        solutions(state, solutions) {
            state.solutions = solutions;

            if (solutions.length > 0) {
                const ix = solutions.map(x => x.Id).indexOf(state.solution);

                if (ix > -1) {
                    return initRouter();
                } else {
                    store.dispatch('setSolution', solutions[0].Id)
                }
            }
        },
        organization(state, organization) {
            if (state.organization !== organization) {
                state.organization = organization
                setConfig({organization: state.organization});
            }
        },
        organizations(state, organizations) {
            state.organizations = organizations;
        },
        solution(state, solution) {
            if (state.solution !== solution) {
                if (window.hj) {
                    window.hj('event', 'solution_changed_' + solution);
                }

                state.solution = setConfig({solution}).solution;
                initRouter(true);
            }
        },
        lang(state, lang) {
            i18n.locale = lang;

            if (window.hj) {
                window.hj('event', 'lang_changed_' + lang);
            }

            state.lang = setConfig({lang}).lang;
        },
        dark(state, dark) {
            vuetify.framework.theme.dark = dark;

            if (window.hj) {
                window.hj('event', dark ? 'dark_mode_selected' : 'light_mode_selected');
            }

            state.dark = setConfig({dark}).dark;
        },
        user(state, value) {
            state.user = value;
        },
        alert(state, value) {
            state.alert = value;
        },
        loading(state, value) {
            state.loading = value;
        },
        addMessage(state, payload) {
            const messages = state.messages || [];
            payload.timeout = payload.timeout || 5000;
            messages.push(payload);
            state.messages = messages;
        },
        removeMessage(state, index) {
            const messages = state.messages || [];
            messages.splice(index, 1);
            state.messages = messages;
        },
        updateMenu(state, menu) {
            state.menu = menu;
        },
        toggleMenuVariant(state) {
            state.menuVariantMini = !state.menuVariantMini;
            setConfig({menuVariantMini: state.menuVariantMini});
        }
    },
    modules: {},
    actions: {
        setSolutions(context, value) {
            context.commit('solutions', value);
        },
        setOrganization(context, value) {
            context.commit('organization', value);
        },
        setOrganizations(context, value) {
            context.commit('organizations', value);
        },
        setSolution(context, value) {
            context.commit('solution', value);
        },
        setLang(context, value) {
            context.commit('lang', value);
        },
        setDark(context, value) {
            context.commit('dark', value);
        },
        setLoading(context, value) {
            context.commit('loading', value);
        },
        checkUser(context) {
            context.commit('user', checkUser());
        },
        signOut(context) {
            context.commit('user', signOut());
        },
        setToken(context, token) {
            setToken(token);
            context.commit('user', checkUser());
        },
        setAlert(context, value) {
            context.commit('alert', value);
        },
        addMessage(context, payload) {
            context.commit('addMessage', payload);
        },
        removeMessage(contex, index) {
            contex.commit('removeMessage', index);
        },
        updateMenu(context, menu) {
            context.commit('updateMenu', menu);
        },
        toggleMenuVariant(context) {
            context.commit('toggleMenuVariant');
        },
        async openIotDevCenter() {
            const url = await configApi.getConfig(config.ConfigKeys.IOT_DEV_CENTER);
            if (url) {
                window.open(url, '_blank');
            }
        }
    }
});

export default store;
