import configApi from '@/services/api/configApi';

const createAsyncConfigService = () => {
    let configs: any;

    return async () => {
        if (configs) {
            return configs
        }

        const res = await configApi.fetchConfigs();
        configs = res.data;

        return configs;
    }
}

const asyncConfigService = createAsyncConfigService();

export interface AsyncConfig {
    get(key: string): Promise<string | null>;
}

export default {
    async get(key: string): Promise<string | null> {
        const configs = await asyncConfigService();

        if (configs) {
            return configs[key];
        }

        return null;
    }
}
