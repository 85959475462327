import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '../locales/en.json';
import tr from '../locales/tr.json';
import {getSettings} from '@/services/application';
import config from '@/config';

const lang = getSettings().lang === undefined ? config.DEFAULT_LANG : getSettings().lang;

Vue.use(VueI18n)

export default new VueI18n({
    locale: lang, messages: {en, tr}
});
