import {
    checkUser,
    getOrganization,
    getSettings,
    getSolution,
    getToken,
    setConfig,
    signOut
} from '@/services/application';
import {createSecureInstance, createUnsecureInstance} from '@/services/api/api';
import asyncConfig from '@/services/asyncConfig';

export default {
    getSolution,
    checkUser,
    createSecureInstance,
    createUnsecureInstance,
    getToken,
    getSettings,
    setConfig,
    signOut,
    asyncConfig,
    getOrganization
}
