




















import Vue from 'vue'
import Component from 'vue-class-component'
import {mapActions, mapState} from 'vuex';

@Component({
    computed: mapState(['messages']),
    methods: mapActions(['removeMessage'])
})
export default class Notification extends Vue {
}
