import asyncConfig from '@/services/asyncConfig';

export const generateIdentityUrl = async () => {
    const identityUrl = await asyncConfig.get('IDENTITY_SERVER_URL');
    const backURL = process.env.VUE_APP_MODE === 'local' ? 'http://localhost:8081/SetToken/' : `${await asyncConfig.get('URL')}/SetToken/`;
    const state = await asyncConfig.get('STATE');
    const platforms = 'Platform360.Common.API Platform360.Tenants.API Platform360.Products.API Platform360.AzureEnablement.API Platform360.PolicyServer.API Platform360.IdentityServer.Users.API Platform360.IdentityServer.ResourceManagement.API';
    const params = '?ReturnUrl=' + encodeURIComponent(`/connect/authorize/callback?response_type=token&client_id=${await asyncConfig.get('CLIENT_ID')}&redirect_uri=${backURL}&scope=${platforms}&state=${state}`);

    return `${identityUrl}/Account/Login${params}`;
}

export const parseToken = (query: string) => {
    const tokenKey = 'access_token=';
    const tokenEndKey = '&token_type';

    return query.substring(query.indexOf(tokenKey) + tokenKey.length, query.indexOf(tokenEndKey));
}

export const uuidv4 = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);

    return v.toString(16);
});
