const SF_FRAME_APP = 'sf_frame_application';

export const addComponentLoader = (url, pre = false) => new Promise((resolve, reject) => {
    url = url.trim();
    const idb = btoa(`${url}p`);

    /* let scripts = document.querySelector(`script[data="${SF_FRAME_APP}"]`);

    if (scripts && scripts.getAttribute('src') !== url) {

        scripts.parentNode?.removeChild(scripts);

        for (const prop in scripts) {
            delete scripts[prop];
        }

        scripts = null;
    } */

    if (document.getElementById(idb)) {
        const idbs = btoa(`${url}s`);
        const scriptElement = document.getElementById(idbs);

        if (scriptElement) {
            const checkLoader = () => {
                if (scriptElement.getAttribute('lds') === idbs) {
                    return resolve();
                }

                if (scriptElement.getAttribute('lde') === idbs) {
                    return reject(new Error());
                }

                setTimeout(checkLoader, 100);

                return false;
            }

            return checkLoader();
        }

        const sc = document.createElement('script');

        sc.setAttribute('id', idbs);
        sc.setAttribute('data', SF_FRAME_APP);
        sc.setAttribute('type', 'text/javascript');
        sc.setAttribute('src', url);

        sc.onload = () => {
            sc.setAttribute('lds', idbs);
            resolve();
        }

        sc.onerror = () => {
            sc.setAttribute('lde', idbs);
            reject(new Error())
        };

        document.head.appendChild(sc);
    } else {
        const sc = document.createElement('link');

        sc.setAttribute('href', url);
        sc.setAttribute('id', idb);
        sc.setAttribute('rel', 'preload');
        sc.setAttribute('as', 'script');

        sc.onload = async function() {
            if (pre) {
                return resolve;
            }

            addComponentLoader(url)
                .then(resolve)
                .catch(reject)
        };

        sc.onerror = reject;
        document.head.appendChild(sc);
    }
});

export const createRender = (name, path, loc, org) => `<${name} id="${path}" lang="${loc}" org="${org}"/>`;
