import {createSecureInstance} from '@/services/api/api';
import asyncConfig from '@/services/asyncConfig';
import {checkUser} from '@/services/application';

const instance = createSecureInstance('', {loader: true});

export default {
    getUserRoutePolicy: async () => {
        const url = await asyncConfig.get('POLICY_URL');

        const user = await checkUser();

        return instance.get(`${url}/v1/policies/distinct-menu-item-permissions-of-user/${user.sub}`);
    },
    getUserSolutions: async () => {
        const url = await asyncConfig.get('POLICY_URL');

        return instance.get(`${url}/v1/policies/getCurrentUsersApplications`);
    },
    getUserOrganizations: async () => {
        const url = await asyncConfig.get('POLICY_URL');
        const user = await checkUser();

        return instance.get(`${url}/v1/users/${user.sub}/roles`);
    }
}
