import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router/router'
import store from '@/store'
import vuetify from './plugins/vuetify'
import './sass/main.scss'
import i18n from '@/plugins/i18n';
import dialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
import {createNotifications} from '@/core/NotificationEngine';
import VueDraggable from 'vue-draggable';
import Components from '@/plugins/Components';
import {defineSf} from '@kocdigital/sf-interface';
import services from '@/services/';
import VueMask from 'v-mask';
import movable from 'v-movable';
import moment from 'moment'
import durationFormat from 'moment-duration-format';
import Hotjar from '@/plugins/Hotjar';

durationFormat(moment);
window.moment = moment;

Vue.use(movable);
Vue.use(VueMask);
Vue.use(VueDraggable);
Vue.use(Hotjar)
Vue.use(dialog, {
    context: {
        vuetify
    }
});

Vue.use(Vuex);

createNotifications(store, 'messages');

const core = {
    store,
    vuetify,
    router
}

export default defineSf(Vue, {
    ...Components,
    ...core,
    i18n,
    render: h => h(App)
}, {
    ...core,
    dialog,
    ...services
});
