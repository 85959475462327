const camelize = str => str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word;
}).replace(/\s+/g, '');

export const rootCamelize = obj => {
    if (obj === null) {
        return null;
    }

    const result: any = {};

    for (const key in obj) {
        result[camelize(key)] = obj[key];
    }

    return result;
}
