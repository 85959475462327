import Vue from 'vue'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css';
import Vuetify, {
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VAutocomplete,
    VAvatar,
    VBadge,
    VColorPicker,
    VColorPickerSwatches,
    VColorPickerCanvas,
    VStepper,
    VBreadcrumbs,
    VStepperStep,
    VBreadcrumbsItem,
    VBtn,
    VCalendar,
    VCard,
    VCardActions,
    VCardText,
    VTextarea,
    VCardTitle,
    VCheckbox,
    VExpansionPanel,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VExpansionPanels,
    VChip,
    VCol,
    VContainer,
    VContent,
    VDataTable,
    VDatePicker,
    VTimePicker,
    VDialog,
    VDivider,
    VFileInput,
    VFlex,
    VForm,
    VIcon,
    VImg,
    VLayout,
    VList,
    VListItem,
    VListItemAction,
    VListItemAvatar,
    VListItemContent,
    VListItemIcon,
    VListItemSubtitle,
    VListItemTitle,
    VMain,
    VMenu,
    VProgressCircular,
    VRow,
    VSelect,
    VSnackbar,
    VSpacer,
    VStepperHeader,
    VStepperItems,
    VSubheader,
    VSwitch,
    VTab,
    VTabItem,
    VTabsItems,
    VTabs,
    VProgressLinear,
    VTabsSlider,
    VTextField,
    VToolbar,
    VToolbarTitle,
    VTooltip,
    VRadio,
    VRadioGroup,
    VStepperContent,
    VTreeview,
    VAlert,
    VInput,
    VListGroup,
    VFooter,
    VSimpleTable,
    VSimpleCheckbox,
    VOverlay,
    VCombobox,
    VSheet,
    VNavigationDrawer,
    VSlider,
    VRangeSlider,
    VSkeletonLoader,
    VDataIterator
} from 'vuetify/lib'
import {getSettings} from '@/services/application';
import NotificaitonMessage from '@/model/NotificaitonMessage';

Vue.use({
    install: (vue: any, options: NotificaitonMessage) => {
        vue.prototype.$addMessage = () => {
            vue.$store.dispatch('addMessage', options);
        }
    }
});

Vue.use(Vuetify, {
    components: {
        VListGroup,
        VFooter,
        VSimpleTable,
        VSimpleCheckbox,
        VOverlay,
        VCombobox,
        VSheet,
        VNavigationDrawer,
        VApp,
        VChip,
        VProgressLinear,
        VRadio,
        VRadioGroup,
        VStepperStep,
        VStepperContent,
        VBtn,
        VIcon,
        VAvatar,
        VListItemSubtitle,
        VCalendar,
        VSwitch,
        VContent,
        VTextarea,
        VDataTable,
        VMain,
        VAppBar,
        VToolbar,
        VAppBarNavIcon,
        VToolbarTitle,
        VSnackbar,
        VDialog,
        VCard,
        VExpansionPanel,
        VExpansionPanelContent,
        VExpansionPanelHeader,
        VExpansionPanels,
        VDatePicker,
        VTimePicker,
        VCardText,
        VListItemAction,
        VSpacer,
        VCardTitle,
        VCardActions,
        VFileInput,
        VLayout,
        VFlex,
        VRow,
        VContainer,
        VCol,
        VTextField,
        VSubheader,
        VForm,
        VTabs,
        VTab,
        VTabsSlider,
        VTabItem,
        VTabsItems,
        VTooltip,
        VTreeview,
        VCheckbox,
        VProgressCircular,
        VBreadcrumbs,
        VBreadcrumbsItem,
        VDivider,
        VStepperHeader,
        VColorPicker,
        VColorPickerSwatches,
        VColorPickerCanvas,
        VStepperItems,
        VSelect,
        VBadge,
        VListItemContent,
        VAutocomplete,
        VMenu,
        VList,
        VListItem,
        VListItemTitle,
        VListItemIcon,
        VListItemAvatar,
        VAlert,
        VImg,
        VStepper,
        VInput,
        VSlider,
        VRangeSlider,
        VSkeletonLoader,
        VDataIterator
    }
})

export default new Vuetify({
    icons: {
        iconfont: 'mdi'
    },
    theme: {
        disable: false,
        dark: getSettings().dark,
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#57638e',
                secondary: '#ffffff',
                accent: '#f0f2f5',
                error: '#b71c1c',
                strange: '#2c3239',
                remark: '#3f79d8',
                colorful: '#687dd5'
            },
            dark: {
                primary: '#57638e',
                secondary: '#1f262f',
                strange: '#d3d5db',
                remark: '#3f79d8',
                colorful: '#687dd5',
                accent: '#13171c',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107'
            }
        }
    }
})
