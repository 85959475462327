







import Vue from 'vue'
import Component from 'vue-class-component'
import {addComponentLoader, createRender} from '../utils/sfutils';
import RouteLoader from '@/components/RouteLoader.vue';
import SFRouter from '@/core/sfframe/router/SFRouter';
import {mapState} from 'vuex';
import {Watch} from 'vue-property-decorator';

@Component({
    name: 'sfframe',
    components: {RouteLoader},
    props: {
        pageName: {
            type: String
        },
        remote: {
            type: String
        }
    },
    computed: {
        ...mapState({orx: 'organization'})
    }
})
export default class SFFrame extends Vue {
    loaded = false;
    orx: string;

    async mounted() {
        try {
            if (this.$props.remote !== 'this') {
                await addComponentLoader(this.$props.remote)
            }
        } catch (e) {
            console.error('Remote component cannot be loaded ', e)
        } finally {
            setTimeout(() => {
                this.loaded = true;
                (this.$router as SFRouter).routerManager.count();
            }, 200);
        }
    }

    get element() {
        return createRender(this.$props.pageName, this.$route.fullPath, this.$i18n.locale, this.orx);
    }

    beforeDestroy() {
        const {wrapper}: any = this.$refs;
        const component = wrapper.firstElementChild;

        if (component.vueComponent) {
            component.vueComponent.$destroy();
        }
    }
}
