export default {
    languages: [
        'en', 'tr'
    ],
    STORAGE: {
        TOKEN_KEY: 'x-tkn',
        SETTINGS_KEY: 'x-cnf'
    },
    ConfigKeys: {
        CONFIG_API_URL: 'CONFIG_API_URL',
        IOT_DEV_CENTER: 'IOT_DEV_CENTER'
    },
    DEFAULT_LANG: process.env.VUE_APP_DEFAULT_LANG,
    DEFAULT_DARK: process.env.VUE_APP_DEFAULT_DARK,
    CONFIG_FILE: process.env.VUE_APP_CONFIG_FILE,
    BASE_URL: process.env.VUE_APP_PUBLIC_PATH
}
