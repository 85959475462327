












































































import Vue from 'vue'
import Component from 'vue-class-component'
import {mapState} from 'vuex';

@Component({
    computed: mapState(['menu'])
})
export default class Drawer extends Vue {
    drawer = true

    set menuVariantMini(value) {
        this.$store.dispatch('toggleMenuVariant');
    }

    get menuVariantMini() {
        return this.$store.state.menuVariantMini;
    }

    getTitle(title) {
        if (typeof title === 'string' || title instanceof String) {
            return title
        }

        if (title?.hasOwnProperty(this.$i18n.locale)) {
            return title[this.$i18n.locale];
        }

        return title?.hasOwnProperty('en') ? title.en : '';
    }

    getIcon(icon) {
        return icon.includes('mdi-') ? icon : `mdi-${icon}`;
    }
}
